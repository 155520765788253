const CheckoutSkeleton = () => {
  return (
    <div className="w-full animate-pulse">
      <div className="flex flex-col gap-6">
        <div className="flex w-full justify-center gap-2">
          <div className="h-[65px] w-full rounded bg-gray-100" />
          <div className="h-[65px] w-full rounded bg-gray-100" />
        </div>
        <div className="flex flex-col justify-center gap-3">
          <div className="h-[45px] w-full rounded bg-gray-100" />
          <div className="h-[45px] w-full rounded bg-gray-100" />
          <div className="h-[45px] w-full rounded bg-gray-100" />
          <div className="h-[45px] w-full rounded bg-gray-100" />
        </div>
      </div>
    </div>
  );
};

export default CheckoutSkeleton;
