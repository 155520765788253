import React, { useEffect } from 'react';

// Components
import { HiX } from "react-icons/hi";

interface IModal_V2Props {
  className?: string;
  show: boolean;
  setShow: (value: boolean | ((prev: boolean) => void)) => void;
  JSX_LEFT?: React.ReactNode,
  children: React.ReactNode;
}

const Modal_V2 = ({
  className = 'bg-[#222222]',
  show,
  setShow,
  JSX_LEFT,
  children
}: IModal_V2Props) => {

  useEffect(() => {
    if (show) {
      document.body.classList.add("overflow-hidden");
      return
    }

    document.body.classList.remove("overflow-hidden")
  }, [show])

  const handleOnMouseUp = (e: any) => {
    if (e.target.classList.contains('iw_modal-v2') && show) {
      return setShow(false);
    }
  };

  return (
    <div
      className={`iw_modal-v2 fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 transition-all duration-500 ${
        show ? 'visible opacity-100' : 'invisible opacity-0'
      }`}
      onMouseUp={handleOnMouseUp}
    >
      <div
        className={`max-h-[90vh] w-11/12 max-w-lg transform overflow-y-auto rounded-lg pb-8 px-7 pt-7 transition-all duration-500 ${
          show ? 'translate-y-0' : '-translate-y-1/2'
        } ${className}`}
      >
        <div className="flex justify-between mb-8">
          <div>
            {
              JSX_LEFT && JSX_LEFT
            }
          </div>

          <HiX
            className="text-gray-400 hover:text-gray-500 w-6 h-6 cursor-pointer"
            onClick={() => setShow(false)}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal_V2;
