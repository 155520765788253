import React, { useEffect, useRef } from 'react';

// Components
import Modal_V2 from 'components/Modal_V2';

interface IPaypalCheckoutProps {
  show: boolean;
  setShow: (value: boolean | ((prev: boolean) => boolean)) => void;
}

const PaypalCheckout = ({ show, setShow }: IPaypalCheckoutProps) => {
  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!show) {
      const $container = divRef.current;

      if (!$container) return;

      $container.innerHTML = '';
    }
  }, [show]);

  return (
    <Modal_V2 className="bg-white" show={show} setShow={setShow}>
      <div id="paypal-button-container" className="pt-4" ref={divRef}></div>
    </Modal_V2>
  );
};

export default PaypalCheckout;
