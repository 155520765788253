import { LockClosedIcon } from '@heroicons/react/solid';

const StripeSeal = () => {
  return (
    <div className="flex gap-2 justify-center items-center w-full ">
      <div className='flex gap-1 items-center'>
        <LockClosedIcon className='text-gray-700 h-4 w-4' />
        <h1 className='text-xs text-gray-700 leading-4 font-semibold'>Pagos seguros y protegidos</h1>
      </div>
      <img className='h-5' src="/StripePowered.svg" />
    </div>
  );
};

export default StripeSeal;
